(function ($) {
	const bodyPage = $("body"),
		header = $("#main-header"),
		menuBtn = $(".close-menu"),
		closeBtn = $(".btn-close"),
		ScrWindow = $(window);

	if ($(window).width() > 991) {
		$(".navbar .dropdown").hover(
			function () {
				$(this)
					.find(".dropdown-menu")
					.first()
					.stop(true, true)
					.delay(200)
					.slideDown();
			},
			function () {
				$(this)
					.find(".dropdown-menu")
					.first()
					.stop(true, true)
					.delay(100)
					.slideUp();
			},
		);

		$(".navbar .dropdown > a").click(function () {
			location.href = this.href;
		});
	}

	const handleOffcanvas = (event) => {
		menuBtn.toggleClass("active");
		bodyPage.toggleClass("overflow-hidden");
	};

	const checkScroll = () => {
		const scrollPosition = ScrWindow.scrollTop();
		if (scrollPosition >= 5) {
			header.addClass("sticky");
		} else {
			header.removeClass("sticky");
		}
	};

	checkScroll();
	menuBtn.on("click", handleOffcanvas);
	closeBtn.on("click", handleOffcanvas);
	$(document).on("click", ".offcanvas-backdrop", handleOffcanvas);

	ScrWindow.scroll(function () {
		checkScroll();
	});
})(jQuery);
